import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { AuthContext } from '../Auth';
import { receivedCategories } from '../components/features/categories/categoriesSlice';
import { receivedUserSettings } from '../components/features/settings/userSettingsSlice';
import { receivedItems } from '../components/features/items/itemsSlice';
import Categories from '../components/features/categories/Categories';
import db from '../config/firebase';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import HeaderOfUser from '../components/features/HeaderOfUser';
import Navigation from '../components/features/Navigation';
import Skeleton from '../components/common/Loadings/Skeleton'; 
import Story from '../components/features/Story/Story'; 
import Cart from '../components/features/cart/Cart'; 
import Subscription from '../components/features/Subscription'; 

const Tenant = ({
}) => {
	const initialLanguageActive = window.localStorage.getItem('activeLanguage') || 'en';

    const dispatch = useDispatch();

	const items = useSelector(state => state.items.items);

	const [ loading, setLoading ] = useState(true);
	const [ businessName, setBusinessName ] = useState('');
	const [ logo, setLogo ] = useState('');
	const [ phone, setPhone ] = useState('');
	const [ activeLanguage, setActiveLanguage ] = useState(initialLanguageActive);
	const [ languagesOpen, setLanguagesOpen ] = useState(false);
	const [ subscriptionExist, setSubscriptionExist ] = useState('');

	const { i18n } = useTranslation();

	const changeLanguage = lng => {
        i18n.changeLanguage(lng);

        setActiveLanguage(lng);
    };

    const languages = [ {
        name: 'en',
        action: () => { changeLanguage('en'); setLanguagesOpen(false) }
    }, {
        name: 'el',
        action: () => { changeLanguage('el'); setLanguagesOpen(false) }
    }, {
		name: 'ru',
		action: () => { changeLanguage('ru'); setLanguagesOpen(false) }
	} ];

    useEffect(() => {
        window.localStorage.setItem('activeLanguage', activeLanguage)
    }, [activeLanguage]);

	const { currentUser } = useContext(AuthContext);

	let location = useLocation();
	const url = location.pathname;
	const idFromUrl = url?.slice(1);

	const docRef = db.firestore().collection("users").doc(idFromUrl);

/*	const getSubscription = async () => {
    	await docRef
  		.collection('subscriptions')
  		.where('status', 'in', ['trialing', 'active'])
  		.onSnapshot(async (snapshot) => {
    	// In this implementation we only expect one active or trialing subscription to exist.
    		const doc = snapshot.docs[0];
    		setSubscriptionExist(doc?.id);
  		});
	};
*/
	const getUserSettings = async () => {
		await docRef.onSnapshot(doc => {
			setLoading(false);
			dispatch(receivedUserSettings(doc.data()))
		}, (error) => {
			console.log(error)
		})
	}

	const getBusinessName = async () => {
		await docRef.onSnapshot(doc => {
			setBusinessName(doc.data()?.displayName);
		}, (error) => {
			console.log(error)
		})
	};

	const getLogo = async () => {
		await docRef.onSnapshot(doc => {
			setLogo(doc.data()?.logoUrl);
		}, (error) => {
			console.log(error)
		})
	};

	const getPhone = async () => {
		await docRef.onSnapshot(doc => {
			setPhone(doc.data()?.phone);
		}, (error) => {
			console.log(error)
		})
	};

	const getCategories = async () => {
    await docRef
	.collection('categories')
	.orderBy('timestamp', 'asc')
	.onSnapshot(snapshot => {
		setLoading(false);
		dispatch(receivedCategories(snapshot.docs.map(doc => ({
			hidden: doc.data().hidden,
			id: doc.id,
			name: doc.data().translated,
			originalName: doc.data().translate
		}) )))
		}, (error) => {
			console.log(error)
		})
  };

  const getItems = async () => {
    await docRef
	.collection('items')
	.orderBy('timestamp', 'asc')
	.onSnapshot(snapshot => {
		setLoading(false);
		dispatch(receivedItems(
			snapshot.docs.map(doc => ({
				categoryId:  doc.data().category,
				description:  doc.data().translated?.description,
				hidden: doc.data().hidden,
				id: doc.id,
				imageUrl: doc.data().imageUrl,
				name: doc.data().translated?.name,
				originalDescription: doc.data().translate.description,
				originalName: doc.data().translate.name,
				price:  doc.data().price,
				useOriginalName: doc.data().useOriginalName,
				vegan: doc.data().vegan,
				vegetarian: doc.data().vegetarian,
				timestamp: doc.data().timestamp
			}))
		))
	}, (error) => {
		console.log(error)
	})
  };

useEffect(() => {
	//getSubscription()
	getCategories();
	getItems();
	getBusinessName();
	getLogo();
	getPhone();
	getUserSettings();
}, []);

	return (
		<Root user={ currentUser && currentUser.uid === idFromUrl }>

			<Wrap>
				<HeaderOfUser userId={ idFromUrl }
					businessName={ businessName }
					logo={ logo }
					lang={ activeLanguage }
					phone={ phone }
				/>

				<Header businessName={ businessName } 
					activeLanguage={ activeLanguage }
					languages={ languages }
					languagesOpen={ languagesOpen }
					logo={ logo }
					onLanguagesClose={ () => setLanguagesOpen(false) }
					onLanguagesOpen={ () => setLanguagesOpen(true) }
					userId={ idFromUrl }
					phone={ phone }
				/>

				<LimitWidth>
				<Logo logo={ logo }>{ logo && <img src={ logo } alt="reaction.menu"/> }</Logo>

				<Story />
				
				{ loading ? <Skeleton /> :
					<>
						<Navigation lang={ activeLanguage }
							logo={ logo }
							userId={ idFromUrl }
						/>

						<Categories items={ items }
							userId={ idFromUrl }
							lang={ activeLanguage }
						/>
					</>
				}
			</LimitWidth>

			</Wrap>
			<Cart lang={ activeLanguage } />

			<Footer />
		</Root>
    );
}

export default Tenant;

const Root = styled.div`
	padding-top: ${ ({ user }) => user ? '64px' : 0 };
	position: relative;
	min-height: 100vh;
	background-color: ${ ({ theme }) => theme.body };
`;

const LimitWidth = styled.div`
	max-width: 600px;
	margin: 0 auto;
`;
const Wrap = styled.div`
	padding-bottom: 168px;
`;

const Logo  = styled.div`
	margin: ${ ({ logo }) => logo ? 0 : '24px' } auto 0;
	z-index: 2;
    height: auto;
    padding: 24px 8px 16px;
    position: relative;
    width: calc(100% - 64px - 64px);
`;