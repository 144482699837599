import React, { useContext, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { AuthContext } from '../../../Auth';
import { useTranslation } from 'react-i18next';
import Block from '../../common/Block';
import LoadingSpinner from '../../common/Loadings/LoadingSpinner';
import ModalFull from '../../common/ModalFull';
import Text from '../../common/Text';
import db from '../../../config/firebase';
import ImageUpload from '../../common/ImageUpload';

const AddNewStoryModal = ({
    onClose,
    open
}) => {
    const settings = useSelector(state => state.userSettings.userSettings);

    const { currentUser } = useContext(AuthContext);

    const [image, setImage] = useState(settings?.storyUrl || null);
    const [progress, setProgress] = useState(false);

    useEffect(() => {
        setImage(settings?.storyUrl);
    }, [ open ]);

    const theme = useTheme();

    const { t } = useTranslation();

    const handleChange = (event) => {
        setImage(event.target.files[0]);
      }; 

    const handleDelete = () => {
        setImage(null);
    };

    const handleSave = async () => {
        setProgress(true);

        const storageRef = db.storage().ref(`images/${ currentUser.uid }/stories`);

        const imageRef = image && storageRef.child(image.name);

        let url = '';

        if (image) {
            try { 
                await imageRef.put(image)
                .then(() => imageRef.getDownloadURL())
                .then((img) => url = img)
            } 
            catch(error) { alert(error) }
        } 

        const docRef = db.firestore().collection("users").doc(currentUser.uid);

		docRef
		.set({
			storyUrl: url
		}, { merge: true });

        onClose();
        setProgress(false);
    };

    return (
        <ModalFull onClose={ onClose }
            title= { t('Story') }
            open={ open }
            onSave={ handleSave }
            label={ progress ? <LoadingSpinner color={ theme.text } size="24px" /> : t("Save") }
    >
        <Block center>
            <Text small center>
                Add any photo that represents your store<br />
                svg, png or jpg formats
            </Text>
        </Block>

        <ImageUpload userId={ currentUser.uid } 
            onImageChange={ handleChange }
            image={ image }
            onDelete={ handleDelete }
            deleteLabel={ t("Delete") }
        />
    </ModalFull>
    )};

export default AddNewStoryModal;