import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    categories: []
}

const categoriesSlice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        receivedCategories(state, action) {
            const categories = action.payload;
            state.categories = categories;
        }
    }
})

export const { receivedCategories } = categoriesSlice.actions;

export default categoriesSlice.reducer;