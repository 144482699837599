import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
    itemsInCart: []
}

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart(state, action) {
            const id = action.payload;
            if (state.itemsInCart.includes(id)) {
                state.itemsInCart = state.itemsInCart.filter(itemId => itemId !== id);
            }
            else {
                state.itemsInCart = state.itemsInCart.concat(id);
            }
        },
        removeFromCart(state, action) {
            const id = action.payload;
            state.itemsInCart = state.itemsInCart.filter(itemId => itemId !== id);
        }

    }
})

export const { addToCart, removeFromCart} = cartSlice.actions;

export default cartSlice.reducer;

export const getTotalPrice = createSelector(
   state => state.cart.itemsInCart,
   state => state.items.items,
   (items, itemsInCart) => {
       let total = 0;

       const priceArr = itemsInCart.filter(item => items.includes(item.id))

       priceArr.forEach(item => {
            total += Number(item.price)
        })

          return total.toFixed(2);
    }
)