import styled from 'styled-components';

const Text = styled.p`
    color: ${ ({ gray, primary, red, theme, color }) =>
        red ? theme.red :
        gray ? theme.brighten :
        color ? color
        : theme.text };
    font-size: 19px;
    line-height: 24px;
    text-align: ${ ({ center }) => center ? 'center' : 'left' };
    margin: 0;
    padding: 0;
    font-family: ${ ({ bold }) => bold ? 'bold, Arial' : 'regular, Arial' };
    opacity: ${ ({ disabled }) => disabled ? 0.3 : 1 };
    text-transform: ${ ({ uppercase }) => uppercase && 'uppercase' };

    ${ ({ small }) => small && `
        font-size: 17px;
        line-height: 20px;
    `};

    ${ ({ big }) => big && `
        font-size: 20px;
        line-height: 24px;
    `};

    ${ ({ ellipsis }) => ellipsis && `
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `};
`;

export default Text;
