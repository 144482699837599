import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ActiveIcon } from '../../img/checked.svg';
import Block from '../common/Block';
import ModalBottom from '../common/ModalBottom';
import Body01 from '../common/typography/Body01';

const Languages = ({
    activeLanguage,
    languages,
    open,
    onOpen,
    onClose,
    className
}) => {
    const { t } = useTranslation();
      
    const languageName = {
        el: 'Ελληνικά',
        en: 'English',
        ru: 'Русский'
    };

    return (
        <Root className={ className }>
            <LanguageLink onClick={ onOpen }>
                <Body01 bold>
                    { languageName[activeLanguage].slice(0,3) }
                </Body01>
            </LanguageLink>

            <ModalBottom open={ open }
                onClose={ onClose }
            >
                { languages.map(language =>
                    <Block onClick={ language.action }
                        key={ language.name }
                    >
                        <StyledBody01 activeLanguage={ activeLanguage === language.name }>
                            { languageName[language.name] }
                        </StyledBody01>

                        { activeLanguage === language.name && <StyledActiveIcon /> }
                    </Block>
                )}
            </ModalBottom>
        </Root>
    );
}

export default Languages;

const StyledActiveIcon = styled(ActiveIcon)`
    width: 20px;
    height: 20px;

    path {
        &:last-of-type {
            fill: ${ ({ theme }) => theme.text }
        }
    }
`;

const LanguageLink = styled.div`
    align-items: center;
    display: flex;
    height: 48px;
    justify-content: center;
    width: 48px;
    border-radius: 50%;
    background: ${ ({ theme }) => theme.border };
`;

const Root = styled.div`

`;

const StyledBody01 = styled(Body01)`
    font-family: ${ ({ activeLanguage }) => activeLanguage && 'bold' };
`;
