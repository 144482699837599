import styled from 'styled-components';

const Body01 = styled.p`
    color: ${ ({ theme, color }) =>
        color ? color
        : theme.text };
    font-size: 18px;
    font-family: ${ ({ theme, bold }) =>
    bold ? 'bold'
    : 'regular' };
    line-height: 24px;
    text-align: ${ ({ center }) =>
    center ? 'center'
    : '' };
`;

export default Body01;
