import styled from 'styled-components';

const Title = styled.h1`
    color: ${ ({ disabled, gray, theme }) =>
        disabled ? theme.disabled : theme.text };
    text-align: ${ ({ center }) => center ? 'center' : 'left' };
    font-size: 20px;
    line-height: 28px;
    font-family: ${ ({ bold }) => bold ? 'bold, Arial' : 'regular, Arial' };
    text-transform: ${ ({ uppercase }) => uppercase && 'uppercase' };

    ${ ({ ellipsis }) => ellipsis && `
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `};

    ${ ({ big }) => big && `
        font-size: 78px;
        line-height: 90px;
        font-family: extraBold, Arial;
    `}

    @media(max-width: ${ ({ theme }) => theme.tabletBreakpoint }) {
        ${ ({ big }) => big && `
            font-size: 43px;
            line-height: 54px;
        `}
    }
`;

export default Title;
