import React from 'react';
import styled from 'styled-components';

import Text from '../common/Text';
import Title from '../common/Title';

const NotFound = ({ title, text }) => {
    return (
        <Root>
            <Content>
            <Text gray bold>
                { title }
            </Text>

            <StyledText gray center>
                { text }
            </StyledText>
            </Content>
        </Root>
    );
}

export default NotFound;

const Root  = styled.div`
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    padding: 0 16px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
`;

const StyledText  = styled(Text)`
    margin-top: 4px;
`;

const Content  = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    width: 100%;
`;