import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Chip from '../../common/Chip';

const CategoriesList = ({
    activeCategory,
    className,
    categories,
    onCategoryChange,
    lang
}) => {
    return (
        <Root className={ className }>
            { categories && categories.map(category =>   
                <StyledChip onClick={ () => onCategoryChange(category) }
                    active={ activeCategory === category.id }    
                    key={ category.id }  
                    withCheckmark          
                >
                    { category?.name ? category.name[lang] : category.translate }
                </StyledChip>
            )}
        </Root>
    );
}

export default CategoriesList;

const Root = styled.div`
    display: flex;
    overflow: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    
    ::-webkit-scrollbar {
        display: none; 
    }
`;

const StyledChip = styled(Chip)`
    margin-right: 8px;
`;