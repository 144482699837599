import React from 'react';
import styled from 'styled-components';

const Icon = ({ 
    background = false,
    color,
    className, 
    icon, 
    onClick, 
    size 
}) => {
    return (
        <Root onClick={ onClick }
            className={ className }
        >
            <Background background={ background }>
                <IconWrapper color={ color }
                    size={ size }
                >
                    { icon }
                </IconWrapper>
            </Background>
        </Root>
    );
}

export default Icon;

const IconWrapper = styled.div`
    height: ${ ({ size }) => size ? `${ size }px` : '24px' };
    width: ${ ({ size }) => size ? `${ size }px` : '24px' };

    svg { 
        height: ${ ({ size }) => size ? `${ size }px` : '24px' };
        width: ${ ({ size }) => size ? `${ size }px` : '24px' };

        path {
            &:last-of-type {
                fill: ${ ({ color, theme }) => color ? color : theme.text };
            }
        }
    }
`;

const Root = styled.div`
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: center;
    width: 64px;
    flex-shrink: 0;
`;

const Background = styled.div`
    ${ ({ background }) => background ? `
        align-items: center;
        background: white;
        border-radius: 50%;
        display: flex;
        height: 40px;
        justify-content: center;
        width: 40px;
    ` : ''}
`;