import React, { useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AuthContext } from '../../Auth';
import AddButton from './AddButton';
import SettingsButton from './settings/SettingsButton';
import Link from '../../components/common/Link';
import firebase from 'firebase';
import LoadingSpinner from '../common/Loadings/LoadingSpinner';

const HeaderOfUser = ({
    userId,
    businessName,
    phone,
    lang,
    logo,
    userTheme
}) => {
    const categories = useSelector(state => state.categories.categories);
    
    const theme = useTheme();

    const { t } = useTranslation();

    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);

    const { currentUser } = useContext(AuthContext);

    const user = currentUser?.uid === userId;

    const handleGoToSubscription = async () => {
        setLoading(true);

        const handleError = () => {
            setLoading(false);

            setError(true);
        }

        const functionRef = firebase
        .app()
        .functions('europe-west6')
        .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');

        const { data } = await functionRef({ returnUrl: `${ window.location.origin }` }).catch(handleError);

        window.location.assign(data.url);
    }

    if (user) {
        return (
        <>
        <Root>
        <SettingsButton  businessName={ businessName } 
            logo={ logo }
            userTheme={ userTheme }
            phone={ phone } 
        />

        { loading ? <LoadingSpinner color={ theme.body } />
            : <Link text={ error ? 'Error! Contact us' : t('Manage subscription') }
                onClick={ handleGoToSubscription }
                small 
                color={ theme.body }
            />
        }

        <StyledAddButton categories={ categories } 
            lang={ lang }
         />
    </Root>
    </>
    )}

    return null
    ;
}

export default HeaderOfUser;

const Root  = styled.div`
    align-items: center;
    background-color: ${ ({ theme }) => theme.text };
    display: flex;
    height: 64px;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
    transition: all 400ms ease;
`;

const StyledAddButton = styled(AddButton)`
`;
