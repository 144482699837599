import React, { useRef } from 'react';
import styled from 'styled-components';

import { useAutoTextAreaSize } from '../../hooks/useAutoTextAreaSize';

const Textarea = ({
    value,
    onChange,
    placeholder
}) => {
    const textAreaRef = useRef(null);

    useAutoTextAreaSize(textAreaRef);

    return (
        <Root value={ value }
            onChange={ onChange }
            placeholder={ placeholder }
            ref={ textAreaRef }
            rows={ 0 }
        />
    )};

export default Textarea;

const Root = styled.textarea`
    -webkit-appearance: none;
    background-color: transparent;
    border-radius: 0;
    border: none;
    color: ${ ({ theme, grey }) => grey ? theme.grey : theme.text };
    font-family: regular;
    font-size: 18px;
    line-height: 24px;
    outline: none;
    padding: 0;
    width: 100%;
    resize: none;
    padding-right: 16px;

    ::placeholder {
        color: ${ ({ theme }) => theme.placeholder };
    }
`;
