import styled from 'styled-components';
import React from 'react';

import LoadingSpinner from './Loadings/LoadingSpinner';

const Button = ({
    className,
    disabled,
    label,
    onClick,
    red,
    primary,
    loading,
    width,
    icon
}) => {

    return (
        <Root className={ className }
            disabled={ disabled }
            onClick={ onClick }
            red={ red }
            primary={ primary }
            loading={ loading }
            width={ width }
        >
            { label }

            { icon && <Icon>{ icon }</Icon> }

            { loading && <StyledLoadingSpinner /> }
        </Root>
    );
}

export default Button;

const Root = styled.button`
    align-items: center;
    background: ${ ({ theme, primary }) => primary ? theme.text : 'transparent' };
    border: none;
    border-radius: 8px;
    color: ${ ({ theme, red, primary }) => red ? theme.red : primary ? theme.body : theme.text };
    cursor: pointer;
    display: flex;
    font-family: bold, Arial;
    justify-content: center;
    font-size: 15px;
    margin: 0;
    outline: none;
    padding: ${ ({ primary }) => primary ? '20px' : 0 };
    pointer-events: ${ ({ loading }) => loading ? 'none' : '' };
    position: relative;
    width: ${ ({ width }) => width ? width : 'auto'};
    text-transform: uppercase;
    letter-spacing: 0.5px;

    &:disabled {
        color: ${ ({ primary, theme }) => primary ? '' : theme.disabled };
        cursor: unset;
    }

    &:active {
        opacity: 0.9;
    }

    &:hover {
        opacity: 0.9;
    }
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
    position: absolute;
    right: 16px;
`;

const Icon = styled.div`
    margin-left: 12px;

    svg{
        width: 20px;   
    }
`;
