import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userSettings: {}
}

const userSettingsSlice = createSlice({
    name: "userSettings",
    initialState,
    reducers: {
        receivedUserSettings(state, action) {
            const userSettings = action.payload;
            state.userSettings = userSettings;
        }
    }
})

export const { receivedUserSettings } = userSettingsSlice.actions;

export default userSettingsSlice.reducer;