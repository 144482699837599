import React from 'react';
import styled from 'styled-components';

const Block = ({
    center = false,
    children,
    className,
    noBorder,
    onClick,
    disabled
}) => {
    return (
        <Root center={ center }
            className={ className }
            noBorder={ noBorder }
            onClick={ onClick }
            disabled={ disabled }
        >
            { children }
        </Root>
    );
}

export default Block;

const Root  = styled.div`
    border-bottom: 1px solid ${ ({ theme, noBorder }) => noBorder ? 'transparent' : theme.border };
    display: flex;
    justify-content: ${ ({ center }) => center ? 'center' : 'space-between' };
    padding: 16px;
    width: 100%;
    pointer-events: ${ ({ disabled }) => disabled ? 'none' : '' };
`;
