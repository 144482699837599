import React from 'react';
import styled from 'styled-components';

import Item from './Item';

const Items = ({
    categories,
    hiddenCategory,
    items,
    lang,
    userId
}) => {
    return (
        <Root>
            { items.map(item => (
                <Item key={ item.id }
                    categories={ categories }
                    hiddenCategory={ hiddenCategory }
                    item={ item }
                    lang={ lang }
                    userId={ userId }
                />
            ))}
        </Root>
    );
}

export default Items;

const Root = styled.div`
`;

