import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import Category from './Category';
import Body02 from '../../common/typography/Body02';
import Body01 from '../../common/typography/Body01';

const Categories = ({
    items,
    invisible,
    userId,
    lang
}) => {
    const categories = useSelector(state => state.categories.categories);
    const theme = useTheme();
    return (
        <Root>
            { categories.length === 0 &&
            <EmptyState>
                <Body01 center bold>
                    Welcome to reaction.menu!
                </Body01>
                <Body02 StyledText color={ theme.textSecondary }>
                    Let's start by creating categories and menu items. Use + button at the right top corner
                    <br /><br />
                    Categories and items are automatically translated into English, Greek and Russian languages.
                    If you are not satisfied with translation, just edit translated text.
                    <br /><br />
                    Please, contact us if you need any help <span role='img' aria-label='smile'>🙂</span>
                </Body02>
            </EmptyState>
            }

            { categories && categories.map(category => (
                <Category key={ category.id }
                    category={ category }
                    items={ items }
                    invisible={ invisible }
                    userId={ userId }
                    categories={ categories }
                    lang={ lang }
                /> 
            )) }
        </Root>
    );
}

export default Categories;

const Root  = styled.div`
    position: relative;
`;

const EmptyState = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 16px 24px;
`;