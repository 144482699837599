import React, { useState, useEffect, useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import  Button from '../../common/Button';
import { AuthContext } from '../../../Auth';
import { ReactComponent as MoreIcon} from '../../../img/pending.svg';
import AddNewItem from '../AddNewItem'
import Block from '../../common/Block';
import db from '../../../config/firebase';
import Input from '../../common/Input';
import Items from '../../features/items/Items';
import ModalBottom from '../../common/ModalBottom';
import ModalFull from '../../common/ModalFull';
import Body02 from '../../common/typography/Body02';
import Text from '../../common/Text';

import Headline06 from '../../common/typography/Headline06';

const Category = ({
    category,
    categories,
    items,
    invisible,
    userId,
    lang
}) => {
    const { t } = useTranslation();

    const theme = useTheme();

    const [ categoryName, setCategoryName ] = useState(category && category.name  && category.name[lang] || '')
    const [ open, setOpen ] = useState(false);
    const [ openEdit, setOpenEdit ] = useState(false);
    const [ openHide, setOpenHide ] = useState(false);
    const [ openDelete, setOpenDelete ] = useState(false);
    const [ openAddItem, setOpenAddItem ] = useState(false);

    const { currentUser } = useContext(AuthContext);

    const user = currentUser && currentUser.uid === userId;

    const categoryRef = currentUser && db.firestore()
    .collection('users')
    .doc(currentUser.uid)
    .collection('categories');

    const itemRef = currentUser && db.firestore()
    .collection('users')
    .doc(currentUser.uid)
    .collection('items');

    const updateCategory = () => {
        categoryRef
        .doc(category.id).set({
            translated: { [lang]: categoryName }
        }, { merge: true })

        setOpenEdit(false);
    };

    const hideCategory = () => {
        categoryRef
        .doc(category.id).set({
            hidden: !category.hidden
        }, { merge: true })

        setOpenHide(false);
    };

    const filteredItems = items.filter(item => item.categoryId === category.id);

    const deleteCategory = () => {
        categoryRef
        .doc(category.id)
        .delete()

        filteredItems.forEach(item =>
            item.categoryId === category.id
            && itemRef.doc(item.id).delete());

        setOpenDelete(false);
    };

    useEffect(() => {
        if (openEdit) {
            setCategoryName(category.name[lang]);
        }
    }, [ openEdit ]);

    if (category.hidden === true && currentUser?.uid !== userId) {
        return null;
    }

    return (
        <Root id={ category.id }>
            <>
                <CategoryName invisible={ invisible }
                    user={ user }
                >
                    <Headline06 bold 
                        ellipsis
                        color={ category.hidden ? theme.disabled : theme.text }>
                        { category?.name ? category.name[lang] : category.originalName }
                    </Headline06>

                    { user &&
                        <StyledMoreIcon 
                            onClick={ () => setOpen(true) } 
                        />
                    }
                </CategoryName>

                { user && filteredItems.length === 0 && !invisible &&
                    <StyledText color={ theme.textSecondary }>
                        No items yet
                    </StyledText>
                }

                <Items items={ filteredItems }
                    hiddenCategory={ category.hidden }
                    userId={ userId }
                    lang={ lang }
                    categories={ categories }
                />
            </>

                <ModalBottom title={ t("Category") }
                    onClose={ () => setOpen(false) }
                    open={ open }
                >
                    <Block center
                        onClick={ () => (setOpenAddItem(true), setOpen(false)) }
                    >
                        <Text>{ t("Add item") }</Text>
                    </Block>

                    <Block center
                        onClick={ () => (setOpenEdit(true), setOpen(false)) }
                    >
                        <Text>{ t("Edit") }</Text>
                    </Block>

                    <Block center
                        onClick={ () => (setOpenHide(true), setOpen(false)) }
                    >
                        <Text>{ category.hidden === true ? t("Make visible") : t("Hide") }</Text>
                    </Block>

                    <Block center
                        onClick={ () => (setOpenDelete(true), setOpen(false)) }
                    >
                        <Text red>{ t("Delete") }</Text>
                    </Block>
                </ModalBottom>

           
                <ModalFull title={ t("Edit category") }
                    disabled={ !categoryName || category.name[lang] === categoryName }
                    onClose={ () => setOpenEdit(false)  }
                    onSave={ updateCategory }
                    open={ openEdit }
                >
                    <Block>
                        <Input value={ categoryName }
                            onChange={ (e) => setCategoryName(e.target.value) }
                            placeholder={ t("Write in any language, it will be translated automatically") }
                            autoFocus
                        />
                    </Block>
                </ModalFull>

                <ModalBottom onClose={ () => setOpenHide(false) }
                    open={ openHide }
                    title={ category.hidden === true ? t('Make category visible?') : t('Hide category?') }
                >
                    <Block center noBorder>
                        <Text small center>The category with all its items will be { category.hidden === true ? 'visible' : 'invisible'} to the customers</Text>
                    </Block>

                    <Block center noBorder>
                        <Button primary
                            label={ category.hidden === true ? t("Make visible") : t("Hide") }
                            onClick={ hideCategory }
                            width="100%"
                        />  
                    </Block>
                </ModalBottom>

                <ModalBottom onClose={ () => setOpenDelete(false) }
                    title={ t("Delete category?") }
                    open={ openDelete }
                >
                    <Block center noBorder>
                        <Text small center>The category with all its items will be deleted</Text>
                    </Block>

                    <Block center noBorder>
                        <DeleteButton label={ t("Delete") }
                            primary
                            onClick={ deleteCategory }
                            width="100%"
                        />
                    </Block>
                </ModalBottom>

                <AddNewItem fixedCategory={ category }
                    onClose={ () => setOpenAddItem(false) }
                    categories={ categories }
                    open={ openAddItem }
                    lang={ lang }
                />
        </Root>
    );
}

export default Category;

const DeleteButton = styled(Button)`
    background-color: ${ ({ theme }) => theme.red }
`;

const Root = styled.div`
    margin: 0 0 16px;

`;

const CategoryName = styled.div`
    align-items: center;
    display: ${ ({ invisible }) => invisible ? 'none' : 'flex' };
    justify-content: space-between;
    padding: 16px 20px 0;
`;

const StyledText = styled(Body02)`
    padding: 16px 24px;
`;

const StyledMoreIcon = styled(MoreIcon)`
    flex-shrink: 0;
`;  