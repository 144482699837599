import {
    useEffect
} from 'react';


export const useAutoTextAreaSize = (ref) => {
    const resize = () => {
        if (ref.current) {
            ref.current.style.height = 'auto';
            ref.current.style.height = `${ ref.current.scrollHeight }px`;
        }
    };

    useEffect(() => {
        resize();
    }, [ ref, ref?.current?.value ]);

    resize();

};