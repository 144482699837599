import styled from 'styled-components';

const Headline06 = styled.h6`
    color: ${ ({ theme, color }) =>
        color ? color
        : theme.text };
    font-family: ${ ({ bold, extraBold }) =>
        bold ? 'bold'
        : extraBold ? 'extraBold'
        : 'regular' };
    font-size: 22px;
    letter-spacing: -0.5px;
    line-height: 24px;
    text-transform: ${ ({ uppercase }) =>
        uppercase ? 'uppercase'
        : '' };
    text-align: ${ ({ center }) =>
        center ? 'center'
        : '' };

    ${ ({ ellipsis }) => ellipsis && `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
    `};
`;

export default Headline06;
