import React, { useState, useContext } from 'react';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../Auth';
import db from '../../config/firebase';
import Input from '../common/Input';
import Block from '../common/Block';
import ModalFull from '../common/ModalFull';

const AddCategory = ({
    onClose,
    open
}) => {
    const { t } = useTranslation();

    const { currentUser } = useContext(AuthContext);

    const [ categoryName, setCategoryName ] = useState('');

    const addCategory = (e) => {
        e.preventDefault();

        db.firestore()
        .collection('users')
        .doc(currentUser.uid)
        .collection('categories')
        .add({
            translate: categoryName.trim(),
            hidden: false,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        });

        onClose();
        setCategoryName('');
    };

    const handleClose = () => {
        onClose();
        setCategoryName('');
    };

    return (
        <ModalFull title={ t("New category") }
            disabled={ !categoryName }
            onClose={ handleClose  }
            onSave={ addCategory }
            open={ open }
        >
            <Block>
                <Input value={ categoryName }
                    onChange={ (e) => setCategoryName(e.target.value) }
                    placeholder={ t("Category name") }
                    autoFocus
                 />
            </Block>
        </ModalFull>
    )
}
export default AddCategory;
