import React from 'react';
import styled, { useTheme } from 'styled-components';

import Body02 from './typography/Body02';

const Chip = ({
    active,
    className,
    children,
    onClick,
    withCheckmark,
    ...rest
}) => {
    const theme = useTheme();

    return (
        <Root onClick={ onClick }
            className={ className }
            active={ active }
        >
            <Body02 color={ active ? theme.body : theme.text }>{ children }</Body02>
        </Root>
    );
}

export default Chip;

const Root = styled.div`
    align-items: center;
    background: ${ ({ active, theme }) => active ? theme.text : theme.body };
    border-radius: 32px;
    border: 1px solid ${ ({ active, theme }) => active ? theme.text : theme.border };
    display: flex;
    outline: none;
    padding: 8px 12px;
    white-space: nowrap;
`;