import React from 'react';
import styled from 'styled-components';

const Caption = ({
    className,
    required,
    children
}) => {
    return (
        <div className={ className }>
            <Text>
                { children }
            </Text>

            { required && <Asterisk>*</Asterisk> }
        </div>
    );
}

export default Caption;

const Text = styled.span`
    color: ${ ({ theme }) => theme.text };
    font-size: 13px;
    line-height: 16px;
`;

const Asterisk = styled.span`
    color: ${ ({ theme }) => theme.text };
    font-size: 13px;
    margin-left: 2px;
`;