import React from 'react';
import styled, { useTheme } from 'styled-components';
import chroma from 'chroma-js';

import { ReactComponent as VeganIcon } from '../../img/vegan.svg';

import Icon from './Icon';

const Tag = ({
    className,
    label
}) => {
    const theme = useTheme();

    return (
        <Root className={ className }
        >
            { label }
        </Root>
    );
}

export default Tag;

const Root = styled.p`
    align-items: center;
    background: ${ ({ theme }) => chroma(theme.green).alpha(0.2) };
    display: flex;
    letter-spacing: 0.25px;
    margin-right: 8px;
    font-family: bold;
    font-size: 14px;
    line-height: 1;
    border-radius: 4px;
    padding: 4px 8px;
    color: ${ ({ theme }) => theme.green };
`;

const StyledIcon = styled(Icon)`
    margin-right: 4px;
`;