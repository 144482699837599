import { useLayoutEffect } from 'react';

let hookLevel = 0;
let originalBodyOverflow = '';

export const useLockBodyScroll = (visible = true) => {

    useLayoutEffect(() => {
        if (visible) {
            hookLevel = hookLevel + 1;

            if (hookLevel === 1) {
                originalBodyOverflow = window.getComputedStyle(document.body).overflow;
                document.body.style.overflow = 'hidden';
            }

            return () => {
                hookLevel = hookLevel - 1;

                if (hookLevel === 0) {
                    document.body.style.overflow = originalBodyOverflow;
                }
            };
        }
    }, [ visible ]);
};